/* eslint-disable @next/next/no-img-element */
"use client";

import Link from "next/link";
import { useRouter } from "next/router";
import {
  ProSidebarProvider,
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";
import {
  presentation,
  memorial,
  activitesEtProgrammes,
  dashboardItems,
  categorieMobileItems,
  memorialItems
} from "../../data/mainMenuData";
import {
  isActiveLink,
  isActiveParent,
  isActiveParentChaild,
} from "../../utils/linkActiveChecker";
import Social from "../common/social/Social";
import ContactInfo from "./ContactInfo";

const MobileMenu = () => {
  const router = useRouter();

  return (
    <>
      <div className="pro-header d-flex align-items-center justify-between border-bottom-light">
        <Link href="/">
          <img src="/img/general/logo-memorial-joola.png" alt="Memorial Le Joola" />
        </Link>
        {/* End logo */}

        <div
          className="fix-icon"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <i className="icon icon-close"></i>
        </div>
        {/* icon close */}
      </div>
      {/* End pro-header */}

      <ProSidebarProvider>
        <Sidebar width="400" backgroundColor="#fff">
          <Menu>
            {/* <SubMenu label="Home">
              {homeItems.map((item, i) => (
                <MenuItem
                  key={i}
                  component={
                    <Link
                      href={item.routePath}
                      className={
                        isActiveLink(item.routePath, router.asPath)
                          ? "menu-active-link"
                          : ""
                      }
                    />
                  }
                >
                  {item.name}
                </MenuItem>
              ))}
            </SubMenu> */}
            {/* End  All Home Menu */}

            <MenuItem
              component={
                <Link
                  href="/"
                  className={
                    router.pathname === "/"
                      ? "menu-active-link"
                      : ""
                  }
                />
              }
            >
              Accueil
            </MenuItem>

            <SubMenu label="Presentation">
              {presentation?.map((item, i) => (
                  <MenuItem
                    key={i}
                    component={
                      <Link
                        href={item.routePath}
                        className={
                          isActiveLink(item.routePath, router.asPath)
                            ? "menu-active-link"
                            : ""
                        }
                      />
                    }
                  >
                    {item.name}
                  </MenuItem>
                ))}
            </SubMenu>
            {/* End  All Categories Menu */}

            <SubMenu label="Le Memorial">
              {memorial?.map((item, i) => (
                  <MenuItem
                    key={i}
                    component={
                      <Link
                        href={item.routePath}
                        className={
                          isActiveLink(item.routePath, router.asPath)
                            ? "menu-active-link"
                            : ""
                        }
                      />
                    }
                  >
                    {item.name}
                  </MenuItem>
                ))}
            </SubMenu>
            {/* End  All Categories Menu */}

            <SubMenu label="Activites & Programmes">
              {activitesEtProgrammes?.map((item, i) => (
                  <MenuItem
                    key={i}
                    component={
                      <Link
                        href={item.routePath}
                        className={
                          isActiveLink(item.routePath, router.asPath)
                            ? "menu-active-link"
                            : ""
                        }
                      />
                    }
                  >
                    {item.name}
                  </MenuItem>
                ))}
            </SubMenu>
            {/* End  All Categories Menu */}
            {/* End  Forum Menu */}


            <MenuItem
              component={
                <Link
                  href="/memorial/galerie"
                  className={
                    router.pathname === "/memorial/galerie"
                      ? "menu-active-link"
                      : ""
                  }
                />
              }
            >
              Médiatèque
            </MenuItem>
          </Menu>
        </Sidebar>
      </ProSidebarProvider>

      <div className="mobile-footer px-20 py-5 border-top-light"></div>

      <div className="pro-footer">
        <ContactInfo />
        <div className="mt-10">
          <h5 className="text-16 fw-500 mb-10">Suivez-nous sur les médias sociaux</h5>
          <div className="d-flex x-gap-20 items-center">
            <Social />
          </div>
        </div>
        <div className="mt-20">
          <Link
            className=" button -dark-1 px-30 fw-400 text-14 bg-green-2 h-50 text-white"
            href="#"
          >
            Achter un ticket
          </Link>
        </div>
      </div>
      {/* End pro-footer */}
    </>
  );
};

export default MobileMenu;
